<template>
  <div class="start-intergration">
    <h4>Start Intergration</h4>
    <p>
      Fantastic you have made it this far! Please book in 30 minutes meeting
      with one of our engineers to start your intergrations.:
    </p>
    <div v-if="isLoading" class="loader">
      One moment getting calendar ready...
    </div>
    <div
      class="calendly-inline-widget"
      style="min-width: 400px; height: 450px"
    ></div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BLink,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BAlert,
    BFormCheckbox,
  },
  data() {
    return {
      value: "",
      context: null,
      isLoading: false,
    };
  },
  // mounted
  mounted() {
    this.isLoading = true;
    Calendly.initInlineWidget({
      url: "https://calendly.com/hydro-h",
      parentElement: document.querySelector(".calendly-inline-widget"),
      prefill: {},
      utm: {},
    });
    window.addEventListener("message", this.handleCalendy);
  },
  methods: {
    onContext(ctx) {
      this.context = ctx;
    },
    handleCalendy() {
      this.isLoading = false;
      if (e.data.event && e.data.event.indexOf("calendly") === 0) {
        console.log("EVENT", e.data);
      }
    },
  },
};
</script>
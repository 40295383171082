<template>
  <div class="market-setup">
    <h4>Marketplace Setup</h4>
    <p>Sign in to AWS Marketplace seller portal and Setup the following :</p>

    <b-row class="mt-2">
      <b-col cols="12" md="8" offset-md="1" class="mb-1">
        <b-form-checkbox
          v-model="selected"
          value="settings__instructions"
          @change="updateSelected"
          class="custom-control-primary"
        >
          Login in here. Go to settings and click on add public profile.
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="8" offset-md="1" class="mb-1">
        <b-form-checkbox
          v-model="selected"
          value="payment__instructions"
          @change="updateSelected"
          class="custom-control-primary"
        >
          Click on the tab payment information and complete your tax and VAT
          information.
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="8" offset-md="1" class="mb-1">
        <b-form-checkbox
          v-model="selected"
          value="account__instructions"
          @change="updateSelected"
          class="custom-control-primary"
        >
          Provide your US Bank account. If you operate outside US and you do not
          own a US Bank account you can use HyperWallet. Click here for more
          information.
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="8" offset-md="1" class="mt-1">
        <div>
          <b-button variant="primary" class="mb-1"
            >Detailed Instructions</b-button
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BLink,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BAlert,
    BFormCheckbox,
  },
  data() {
    return {
      selected: [],
    };
  },
  mounted() {
    this.selected = this.$store.state["app-market"].marketIntergration
  },
  methods: {
    updateSelected() {
      this.$store.dispatch(
        "app-market/SAVE_MARKET_INTERGRATION",
        this.selected
      );
    },
  },
};
</script>
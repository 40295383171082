<template>
  <div class="account-setup">
    <h4>Account Setup</h4>
    <p>Follow the prompts and ensure the following criteria are met:</p>

    <b-row class="mt-2">
      <b-col cols="12" md="7" offset-md="1" class="mb-1">
        <b-form-checkbox
          v-model="selected"
          @change="updateSelected"
          value="aws__account__created"
          class="custom-control-primary"
        >
          Create a new AWS Account. This account will be used for creating the
          listing and all intergrations. Click here for more information.
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="7" offset-md="1" class="mb-1">
        <b-form-checkbox
          v-model="selected"
          @change="updateSelected"
          value="aws__account__logged__in"
          class="custom-control-primary"
        >
          Login to the new account
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="7" offset-md="1" class="mb-1">
        <b-form-checkbox
          v-model="selected"
          @change="updateSelected"
          value="seller__account__registration"
          class="custom-control-primary"
        >
          <div>Register as a seller account</div>
          <div class="mt-1">
            <ul>
              <li>
                Visit the new
                <a target="_blank" href="https://aws.amazon.com/console/"
                  >AWS Account Portal</a
                >
              </li>
              <li>Click on Signup as a new MarketPlace Seller</li>
              <li>Review Terms and Conditions</li>
            </ul>
          </div>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="7" offset-md="1" class="mb-1">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-checkbox v-model="deploy" class="custom-control-primary">
              Deploy the following template into the account
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6">
            <div style="display: flex; justify-content: right" class="mt-2">
              <b-button @click="launchTemplate" variant="primary" class="mb-1"
                >Launch Template</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="7" offset-md="1" class="mt-1">
        <div>
          <label for="cvv" class="mr-1 mb-1">
            Your MarketPlace AWS Account ID:
          </label>
          <div class="mr-1 mb-1">
            <validation-provider
              #default="{ errors }"
              name="account id"
              rules="required|digits:12"
            >
              <b-form-input
                placeholder="MarketPlace AWS Account ID"
                id="account__id"
                :state="errors.length > 0 ? false : null"
                v-model="account__id"
                type="number"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BLink,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BAlert,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      account__id: "",
      selected: [],
      deploy: false,

      // validation
      required,
    };
  },
  mounted() {
    this.selected = this.$store.state["app-market"].marketIntergration;
  },
  methods: {
    updateSelected() {
      this.$store.dispatch(
        "app-market/SAVE_MARKET_INTERGRATION",
        this.selected
      );
    },
    launchTemplate() {
      this.account__id = "";
    },
  },
};
</script>